import {Controller} from "@hotwired/stimulus"
import Modal from "../../components/modal.svelte"
import {mount} from 'svelte'

export default class extends Controller {

    connect() {
        const modal_btn = this.element.querySelector('#show-modal')
        modal_btn.addEventListener('click', () => this.showModal())
    }

    showModal() {
        const modal = document.createElement('modal-tag')
        mount(Modal, {target: modal, props: {title: 'Test-Title', message: 'Hello Modal'}})
        // modal.setAttribute('title', 'Test-Titel')
        // modal.setAttribute('message', 'Hallo Modal')
        document.body.append(modal)
    }

}